var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _c("b-table", {
        staticClass: "small",
        attrs: {
          id: "billingTable",
          fields: _vm.headers,
          items: _vm.billing,
          bordered: "",
          "sticky-header": "300px",
          "show-empty": "",
          "primary-key": "id",
          "tbody-tr-class": "align-middle",
          striped: ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(number)",
            fn: function(data) {
              return [
                _c("strong", [_vm._v("x" + _vm._s(data.item.number))]),
                data.item.routing_number
                  ? _c("div", [
                      _c("br"),
                      _c("strong", [_vm._v("Routing Number: ")]),
                      _vm._v(_vm._s(data.item.routing_number) + " ")
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(id)",
            fn: function(data) {
              return [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "warning",
                      title: "Edit",
                      size: "sm",
                      disabled: _vm.loading || _vm.showModal
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.edit(data.item.id)
                      }
                    }
                  },
                  [_c("b-icon-pencil-fill")],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "b-button",
        {
          attrs: { variant: "danger" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.edit()
            }
          }
        },
        [_vm._v("Add New Payment Method")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "editPaymentModal",
            "hide-footer": "",
            size: "lg",
            "body-class": "pb-0",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _vm._v(
                    _vm._s(
                      +_vm.selectedId
                        ? "Edit Billing ID " + _vm.selectedId
                        : "Add Payment Information"
                    )
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("PaymentEdit", {
            attrs: { id: +_vm.selectedId, "company-id": +_vm.companyId },
            on: {
              close: function($event) {
                return _vm.$bvModal.hide("editPaymentModal")
              },
              refresh: function($event) {
                _vm.$bvModal.hide("editPaymentModal")
                _vm.load()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }