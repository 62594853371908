var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticClass: "text--black",
      staticStyle: { "text-align": "left" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "mb-4", attrs: { cols: "12", md: "4" } }, [
            _c(
              "div",
              { staticClass: "text-left py-1 d-inline" },
              [_c("back-button", { attrs: { fallback: "/admin/company" } })],
              1
            ),
            _c("div", { staticClass: "h2 d-inline ml-4 text--white" }, [
              _vm._v(_vm._s(_vm.name))
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", md: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: _vm.active == 1 ? "danger" : "info",
                    block: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteComp()
                    }
                  }
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: _vm.active == 1 ? "x-circle" : "arrow-clockwise"
                    }
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.active == 1 ? "Disable" : "Enable") + " "
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", md: "4" } },
            [
              _c("EditCompany", {
                attrs: { compId: +_vm.id, edit: "" },
                on: {
                  refresh: function($event) {
                    return _vm.init()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.organization_id > 0
        ? _c(
            "UserList",
            {
              attrs: {
                organizationId: _vm.organization_id,
                companyId: +_vm.id,
                disabled: false
              }
            },
            [_c("div", { staticClass: "d-inline h4" }, [_vm._v("Users")])]
          )
        : _vm._e(),
      _c(
        "b-card",
        {
          staticClass: "mb-4 card-border text--black q-table-container",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", { staticClass: "d-inline h4" }, [
                    _vm._v("Payment Information")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("PaymentInfo", { attrs: { "company-id": +_vm.id } })],
        1
      ),
      _c(
        "b-card",
        {
          staticClass: "mb-4 card-border text--black q-table-container",
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", { staticClass: "d-inline h4" }, [
                    _vm._v("Quickbooks")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _vm.qbdata && _vm.qbdata.qbpath && !_vm.editPath
                ? _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("b", [_vm._v("Path:")]),
                      _vm._v(" " + _vm._s(_vm.qbdata.qbpath) + " "),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "danger" },
                          on: { click: _vm.deleteQBConnection }
                        },
                        [_vm._v("Delete Connection")]
                      )
                    ],
                    1
                  )
                : _c("b-col", { attrs: { cols: "12", md: "9" } }, [
                    _vm._v(" No company file connected! ")
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }